
import { storage, key } from "@/lib/storage";
import { defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      const sessionID = route.query.sessionId;
      const isOnboarding = route.query.isOnboarding;
      const step = route.query.onboardingStepRequired;


      const localStorage = storage.getLocalStorage();
      localStorage.add(key.sessionId, sessionID);


      switch (step) {
        case "terms": {
          router.push({ name: "Welcome", query: route.query });
          break;
        }
        case "company": {
          router.push({ name: "Organization", query: route.query });
          break;
        }
        case "user": {
          router.push({ name: "Profile", query: route.query });
          break;
        }
      }

      const guestCode = localStorage.get("guestCode");
      const requireAccessCode = localStorage.get("requireAccessCode");
      let contractId;
      if(guestCode) {
        const decode = Buffer.from(guestCode, "base64").toString();
        const details = JSON.parse(decode);
        contractId = details.contractId;
      }

      if(contractId) {
        localStorage.remove("requireAccessCode");
        localStorage.add("userType", "user");
        if(requireAccessCode === "true")
          router.push({name: "Access Code"})
        else 
          router.push(`/sign/details?contractId=${contractId}`);
      }

      else if (!isOnboarding) {
        router.push({ name: "Dashboard" });
        return;
      }

     
    });
  },
});
